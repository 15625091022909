<template>
    <div class="login-container d-flex align-items-center">
        <div class="align">
            <h1 class="fw-bold color-lavender-purple mb-4">404</h1>
            <p class="fw-bold text-black">Page Not Found</p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PageNotFound'
};
</script>
<style scoped>
h1 {
font-size: 60px;
}
.align {
    text-align: left;
    margin-right: 2%;
    width: 30%;
    margin-left: auto;
}
</style>
